<template>
  <div>
    <v-row dense>
      <v-col cols="4">
        <phone-input
          v-model="form.mobile_phone"
          :label="$t('PROFILE.MOBILE_PHONE')"
          :readonly="readonly"
          :error-messages="errors.mobile_phone"
          @input="updateValue('mobile_phone', $event)"
        ></phone-input>
      </v-col>
      <v-col cols="4">
        <phone-input
          v-model="form.phone"
          :label="$t('PROFILE.PHONE')"
          :readonly="readonly"
          :error-messages="errors.phone"
          @input="updateValue('phone', $event)"
        ></phone-input>
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="form.marital_status_id"
          :items="marital_statuses"
          item-text="name"
          item-value="id"
          :clearable="!readonly"
          :label="$t('PROFILE.MARITAL_STATUS')"
          :readonly="readonly"
          :error-messages="errors.marital_status"
          @change="updateValue('marital_status_id', $event)"
        ></v-select>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="4">
        <v-text-field
          v-model="form.street"
          :label="$t('PROFILE.STREET')"
          :readonly="readonly"
          :error-messages="errors.street"
          @input="updateValue('street', $event)"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <zip-code-input
          v-model="form.zip"
          :readonly="readonly"
          :error-messages="errors.zip"
          @input="updateValue('zip', $event)"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="form.city"
          :label="$t('PROFILE.CITY')"
          :readonly="readonly"
          :error-messages="errors.city"
          @input="updateValue('city', $event)"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import formEditable from '@/mixins/form-editable';
import { mapCacheActions } from 'vuex-cache';
import { mask } from 'vue-the-mask';
import i18nRefreshData from '@/mixins/i18nRefreshData';
import ZipCodeInput from '@/components/ZipCodeInput';

export default {
  name: 'PersonalProfileForm',

  directives: {
    mask,
  },

  components: {
    ZipCodeInput,
    PhoneInput: () => import('@/components/phone/PhoneInput'),
  },

  mixins: [formEditable, i18nRefreshData],

  data() {
    return {
      marital_statuses: [],
      liveswith: [],
      form: { marital_status: { id: null, name: null } },
    };
  },

  mounted() {
    this.refreshData();
  },

  methods: {
    ...mapCacheActions('inhabitant_change', ['getMaritalStatuses']),

    refreshData() {
      this.getMaritalStatuses().then(({ data }) => {
        this.marital_statuses = data;
      });
    },
  },
};
</script>

<style scoped></style>
