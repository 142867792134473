<template>
  <div>
    <v-row dense>
      <v-col cols="12" md="3">
        <v-select
          v-model="form.apartment.building.building_complex_id"
          name="building_complex_id"
          item-text="name"
          item-value="id"
          :items="buildingComplexes"
          :readonly="readonly"
          :label="`${$t('BUILDING_COMPLEXES.ITEM')} *`"
          :error-messages="errors.building_complex_id"
          :rules="getRulesByName('building_complex_id')"
        ></v-select>
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          v-model="form.apartment.building_id"
          name="building_id"
          :item-text="(i) => [i.street, i.name].filter(Boolean).join(' — ')"
          item-value="id"
          :items="buildings"
          :readonly="readonly"
          :label="`${$t('BUILDINGS.ITEM')} *`"
          :error-messages="errors.building_id"
          :rules="getRulesByName('building_id')"
        ></v-select>
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          v-model="form.apartment_id"
          item-text="name"
          item-value="id"
          name="apartment_id"
          :items="apartments"
          :readonly="readonly"
          :label="`${$t('APARTMENTS.ITEM')} *`"
          :error-messages="errors.apartment_id"
          :rules="getRulesByName('apartment_id')"
          @change="updateValue('apartment_id', $event)"
        ></v-select> </v-col
      ><v-col cols="12" md="3">
        <v-text-field
          v-if="readonly"
          :value="form.date_from | dateFormat"
          :label="$t('INHABITANTS.DATE_FROM')"
          :error-messages="errors.date_from"
          role="button"
          append-icon="mdi-calendar"
          readonly
        ></v-text-field>
        <v-menu
          v-else
          ref="menuDateFrom"
          v-model="menuDateFrom"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="form.date_from | dateFormat"
              :label="`${$t('INHABITANTS.DATE_FROM')} *`"
              append-icon="mdi-calendar"
              clearable
              readonly
              role="button"
              autocomplete="nope"
              :error-messages="errors.date_from"
              :rules="getRulesByName('date_from')"
              @click:clear="clearFrom"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            ref="picker"
            v-model="form.date_from"
            min="1940-01-01"
            first-day-of-week="1"
            @change="saveDateFrom"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import formEditable from '@/mixins/form-editable';

import { dateFormat } from '@/filters';
import { debounce, cloneDeep, isEqual, isEmpty } from 'lodash';
import { BuildingComplexesService } from '@/services/building-complexes.service';
import { notEmpty } from '@/rules';

const DEFAULT_FORM = {
  apartment_id: null,
  apartment: {
    id: null,
    building_id: null,
    building: {
      id: null,
      building_complex_id: null,
      building_complex: {},
    },
  },
};

export default {
  name: 'InhabitantForm',

  filters: {
    dateFormat,
  },

  mixins: [formEditable],

  props: {
    delay: {
      type: Number,
      default: 100,
    },
  },

  data() {
    return {
      form: cloneDeep(DEFAULT_FORM),
      buildingComplexes: [],
      buildings: [],
      apartments: [],
      menuDateFrom: null,

      getDataBuildingsDebounced: null,
      getDataApartmentsDebounced: null,

      rules: {
        apartment_id: [notEmpty],
        building_id: [notEmpty],
        building_complex_id: [notEmpty],
        date_from: [notEmpty],
      },
    };
  },

  computed: {
    listeners: function () {
      return Object.assign({}, this.$listeners, {
        change: [this.deleteData, this.emitter],
      });
    },
  },

  watch: {
    'form.apartment.building.building_complex_id':
      'changeSelectedBuildingComplex',
    'form.apartment.building_id': 'changeSelectedBuilding',
  },

  created() {
    this.setData();
    this.getDataBuildingsDebounced = debounce(
      this.getDataBuildings,
      this.delay
    );
    this.getDataApartmentsDebounced = debounce(
      this.getDataApartments,
      this.delay
    );
  },

  methods: {
    valueWatchHandler(formProps) {
      if (isEqual(formProps, this.form)) {
        return;
      }

      if (isEmpty(formProps)) {
        this.form = cloneDeep(DEFAULT_FORM);
      } else {
        this.form = {
          ...this.form,
          ...formProps,
        };
      }

      this.emitter();
    },

    setData() {
      this.getDataBuildingComplexes()
        .then(() => {
          return this.getDataBuildings();
        })
        .then(() => {
          return this.getDataApartments();
        });
    },

    getDataBuildingComplexes() {
      return BuildingComplexesService.getSimplifiedList().then(({ data }) => {
        this.buildingComplexes = data;
        return data;
      });
    },

    getDataBuildings(
      buildingComplexId = this.form.apartment.building.building_complex_id
    ) {
      if (buildingComplexId) {
        return BuildingComplexesService.getBuildingComplexBuildings(
          buildingComplexId
        ).then(({ data }) => {
          this.buildings = data;
          return data;
        });
      }
    },

    getDataApartments(buildingId = this.form.apartment.building_id) {
      if (buildingId) {
        return BuildingComplexesService.getBuildingApartments(buildingId).then(
          (data) => {
            this.apartments = data.data;
            return data;
          }
        );
      }
    },

    changeSelectedBuildingComplex(id) {
      if (id !== this.value.apartment.building.building_complex_id) {
        this.form.apartment_id = null;
        this.buildings = [];
        this.form.apartment = cloneDeep(DEFAULT_FORM.apartment);
        this.form.apartment.building.building_complex_id = id;
      }
      this.form.apartment.building.building_complex = Object.assign(
        this.form.apartment.building.building_complex,
        this.buildingComplexes.filter((item) => item.id === id).pop()
      );

      this.apartments = [];

      return this.getDataBuildingsDebounced();
    },

    changeSelectedBuilding(id) {
      this.form.apartment.building_id = id;
      this.form.apartment.building.street = (
        this.buildings.filter((item) => item.id === id).pop() || {}
      ).street;

      this.form.apartment_id = null;
      return this.getDataApartmentsDebounced();
    },

    clearFrom() {
      this.form.date_from = null;
    },
    saveDateFrom(date) {
      this.$refs.menuDateFrom.save(date);
      this.updateValue('date_from', date);
    },
  },
};
</script>
