<template>
  <div>
    <v-row dense>
      <v-col cols="4">
        <v-select
          v-model="form.salutation_id"
          :items="salutations"
          :clearable="!readonly"
          item-text="long_name"
          item-value="id"
          :label="$t('PROFILE.SALUTATION')"
          :readonly="readonly"
          :error-messages="errors.salutation"
          @change="updateValue('salutation_id', $event)"
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="form.first_name"
          :label="`${$t('PROFILE.FIRST_NAME')} *`"
          :rules="rules.first_name"
          :readonly="readonly"
          :error-messages="errors.first_name"
          @input="updateValue('first_name', $event)"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="form.last_name"
          :label="`${$t('PROFILE.LAST_NAME')} *`"
          :rules="rules.last_name"
          :readonly="readonly"
          :error-messages="errors.last_name"
          @input="updateValue('last_name', $event)"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="4">
        <v-select
          v-model="form.language_id"
          :items="languages"
          item-value="id"
          item-text="name"
          :label="$t('PROFILE.LANGUAGE')"
          :readonly="readonly"
          :error-messages="errors.language_id"
          @change="updateValue('language_id', $event)"
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="form.gender_id"
          :items="genders"
          item-text="name"
          item-value="id"
          :clearable="!readonly"
          :label="$t('PROFILE.GENDER')"
          :readonly="readonly"
          :error-messages="errors.gender"
          @change="updateValue('gender_id', $event)"
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="birthday"
          v-mask="'##.##.####'"
          :error-messages="errors.birthday"
          hint="##.##.####"
          :label="$t('PROFILE.BIRTH_DATE')"
          append-icon="mdi-calendar"
          :clearable="!readonly"
          :rules="rules.birthday"
          :readonly="readonly"
          @click:clear="clearBirthday"
          @input="updateValue('birthday', $event)"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import formEditable from '@/mixins/form-editable';
import { mapCacheActions } from 'vuex-cache';
import { mapGetters } from 'vuex';
import moment from 'moment';
import { mask } from 'vue-the-mask';
import { notEmpty } from '@/rules';
import i18nRefreshData from '@/mixins/i18nRefreshData';

export default {
  name: 'PersonForm',

  directives: {
    mask,
  },
  mixins: [formEditable, i18nRefreshData],

  data() {
    return {
      form: {
        birthday: null,

        first_name: null,
        gender: { id: null, name: null },

        language: {
          id: null,
          language_id: null,
          name: null,
          description: null,
        },
        language_id: 1,
        last_name: null,

        reminder_address: null,
        salutation: { id: null, name: null },
        status: { id: null, name: null, color: null },
      },
      formatDate: 'DD.MM.YYYY',
      statuses: [],
      genders: [],
      salutations: [],
      birthday: null,
      languages: [],
      rules: {
        first_name: [notEmpty],
        last_name: [notEmpty],
        birthday: [this.validateBirthday],
      },
    };
  },

  computed: {
    ...mapGetters(['loading']),
  },

  mounted() {
    this.refreshData();
  },

  created() {
    this.birthday = this.form.birthday
      ? moment(this.form.birthday, 'YYYY-MM-DD').format(this.formatDate)
      : null;
  },

  methods: {
    ...mapCacheActions('inhabitant_change', ['getSalutations', 'getGenders']),
    ...mapCacheActions('localization', ['retrieveLanguages']),
    validateBirthday() {
      if (this.birthday) {
        if (
          !moment(this.birthday, this.formatDate, true).isValid() ||
          !moment(this.birthday, this.formatDate).isBetween(
            '1900-01-01',
            moment()
          )
        ) {
          return this.$t('MESSAGE.INVALID_DATE');
        }
        this.form.birthday = moment(this.birthday, this.formatDate).format(
          'YYYY-MM-DD'
        );
        return true;
      }
      this.form.birthday = '';
      return true;
    },
    clearBirthday() {
      this.birthday = null;
      this.form.birthday = null;
    },

    refreshData() {
      this.getSalutations().then(({ data }) => {
        this.salutations = data;
      });
      this.getGenders().then(({ data }) => {
        this.genders = data;
      });
      this.retrieveLanguages().then((response) => {
        this.languages = response.data;
      });
    },
  },
};
</script>

<style scoped></style>
