<template>
  <v-text-field
    :maxlength="counter"
    :value="value"
    name="zip"
    :counter="counter"
    :readonly="readonly"
    :error-messages="errorMessages"
    :rules="postcodeRules"
    :hint="postcodeRuleCh"
    :label="label"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { postcodeValidator } from 'postcode-validator';
import { maxLength, minLength } from '@/rules';

export default {
  name: 'ZipCodeInput',

  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default() {
        return this.$t('PROFILE.ZIP');
      },
    },
    counter: {
      type: Number,
      default: 5,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: [String, Array],
      default: () => [],
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    postcodeRules() {
      return [
        ...this.rules,
        minLength(4),
        maxLength(this.counter),
        this.postcodeRule,
      ];
    },
    postcodeRule() {
      return (v) => postcodeValidator(v, 'INTL') || this.$t('ZIP.INV_ERROR');
    },
    postcodeRuleCh() {
      return (this.value || '').length === 0 ||
        postcodeValidator(this.value, 'CH') ||
        postcodeValidator(this.value, 'DE')
        ? null
        : this.$t('ZIP.INC_ERROR');
    },
  },
};
</script>

<style scoped></style>
