export default {
  data: () => ({
    unwatch: null,
  }),

  created() {
    this.unwatch = this.$store.watch(
      (state) => state.localization.lang,
      () => {
        const lang = this.$store.state.localization.lang;

        if (lang) {
          this.refreshData();
        }
      }
    );
  },

  destroyed() {
    this.unwatch();
  },

  methods: {
    refreshData() {
      throw new Error('"refreshData" function is not implemented');
    },
  },
};
