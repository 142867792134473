<template>
  <v-card class="checkin-sheet" flat dense>
    <v-card-title v-if="!!Object.keys(form).length">
      <div class="d-inline-flex align-baseline">
        <span class="text-h5"
          >{{ $t('ITEM.EDIT') }}
          {{ $t('INHABITANT_CHANGES.REQUEST').toLowerCase() }}</span
        >
      </div>
      <v-spacer></v-spacer>

      <div class="d-inline-flex align-baseline">
        <v-select
          v-model="form.status_id"
          solo
          flat
          :items="statuses"
          item-value="id"
          item-text="name"
          small-chips
          hide-details
          dense
          style="width: 136px"
          @change="updateValue('status_id', $event)"
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              small
              :color="item.color"
              dark
              class="ml-1"
              v-bind="attrs"
              :input-value="selected"
              @click="select"
            >
              {{ item.name }}
            </v-chip>
          </template>
        </v-select>
      </div>

      <v-btn icon @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text dense>
      <v-form ref="form" v-model="isValid">
        <checkin
          v-model="form"
          :errors="errors"
          @update-prop="updateValue($event, value)"
        ></checkin>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <slot name="buttons" />
    </v-card-actions>
  </v-card>
</template>

<script>
import { isEqual } from 'lodash';
import formEditable from '@/mixins/form-editable';
import Checkin from '@/modules/inhabitant_changes/components/forms/Checkin';
import i18nRefreshData from '@/mixins/i18nRefreshData';
import { mapCacheActions } from 'vuex-cache';
import { APPROVED } from '@/modules/inhabitant_changes/common/statuses';

export default {
  name: 'EditCheckinCard',
  components: { Checkin },

  mixins: [formEditable, i18nRefreshData],

  data: () => ({
    isValid: true,
    statuses: [],
    form: {},
  }),

  watch: {
    isValid() {
      this.$emit('isValid', this.isValid);
    },
  },

  created() {
    this.refreshData();
  },

  methods: {
    ...mapCacheActions('inhabitant_change', ['getStatuses']),

    refreshData() {
      this.getStatuses().then(({ data }) => {
        this.statuses = data.map((el) => {
          return el.id === APPROVED ? { ...el, disabled: true } : el;
        });
      });
    },

    updateValue(key, value) {
      this.emitter();
      this.updateProp(key, value);
    },

    valueWatchHandler(formProps) {
      if (isEqual(formProps, this.form)) {
        return;
      }

      this.form = {
        ...this.form,
        ...formProps,
      };

      this.form.data.inhabitant = Object.assign(
        {},
        {
          apartment: this.form.apartment,
          apartment_id: this.form.apartment_id,
        },
        this.form.data.inhabitant
      );

      this.emitter();
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss">
.checkin-sheet {
  .col-12 {
    padding: 6px;
  }
}
</style>
