<template>
  <div>
    <person-form
      v-model="form.data.person"
      :errors="errors.person"
      @update-prop="updateValue(`person.${$event}`, value)"
    />
    <personal-profile-form
      v-model="form.data.personal_profile"
      :person="form"
      :errors="errors.personal_profile"
      @update-prop="updateValue(`personal_profile.${$event}`, value)"
    />
    <v-row>
      <v-col cols="12" md="12">
        <v-textarea
          v-model="form.data.personal_profile.add_info"
          :counter="1500"
          outlined
          :rules="rules.textDescription"
          :error-messages="(errors.inhabitant || {}).add_info"
          :label="$t('ITEM.ADDITIONAL_INFO')"
          @input="updateValue('inhabitant.additional_info', $event)"
        ></v-textarea>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12">
        <v-text-field
          v-model="form.data.user.email"
          :label="`${$t('USERS.EMAIL')}`"
          :rules="rules.email"
          :error-messages="(errors.user || {}).email"
          @input="updateValue('user.email', $event)"
        ></v-text-field>
      </v-col>
    </v-row>

    <inhabitant-form
      v-model="form.data.inhabitant"
      :errors="errors.inhabitant"
      @update-prop="updateValue(`inhabitant.${$event}`, value)"
    />
  </div>
</template>

<script>
import InhabitantForm from '@/modules/inhabitant_changes/components/forms/InhabitantForm';
import PersonalProfileForm from '@/modules/persons/components/forms/MainInformation/PersonalProfileForm';
import PersonForm from '@/modules/persons/components/forms/MainInformation/PersonForm';
import { isEmail, maxLength } from '@/rules';
import formEditable from '@/mixins/form-editable';

export default {
  name: 'Checkin',
  components: { PersonalProfileForm, PersonForm, InhabitantForm },

  mixins: [formEditable],

  data: () => ({
    form: {},
    rules: {
      textDescription: [maxLength(1500)],
      email: [isEmail],
    },
  }),
};
</script>
